import { axios } from 'data';
import { Uuid } from 'types/general';

import {
  ComplaintSortField,
   FetchAttractiveUsersResponse,
  FetchComplaintsParams,
  SortOrder,
} from 'types/api';

const fetchUsers = async (params?: FetchComplaintsParams) => {
  const response = await axios.get<FetchAttractiveUsersResponse>('/admin/user', {
    params: {
      sort: [
        `${ComplaintSortField.STATUS},${SortOrder.ASC}`,
        `${ComplaintSortField.DATE},${SortOrder.ASC}`,
      ],
      ...params,
    },
  });

  return response.data;
};

const updateUser = async (data: { userId: Uuid, attractive: boolean }) => {
  if (data.attractive) {
    await axios.put(`/admin/user/${data.userId}/attractive`);
  }   else {
    await axios.put(`/admin/user/${data.userId}/unattractive`);
  }
}

const attractiveUserService = {
  fetchUsers,
  updateUser
};

export default attractiveUserService;
